import React, { useState } from "react"

import Layout from "../templates/Page"
import FilterModal from "../components/ContentBuilder/Modal/FilterModal"
import QuickViewModal from "../components/ContentBuilder/Modal/QuickViewModal"
import Dropdown from "../components/ContentBuilder/Dropdown"
import Header from "../components/ContentBuilder/Header"
import FilterResult from "../components/ContentBuilder/FilterResult"
import CategoryDropdown from "../components/ContentBuilder/CategoryDropdown"

const ExplorePage = () => {
  const [showModal, setShowModal] = useState(false)
  const [showLetStart, setShowLetStart] = useState(true)
  const [showFilter, setShowFilter] = useState(false)
  const [showIntro, setShowIntro] = useState(true)
  const [selections, setSelections] = useState([])
  const [removeItem, setRemoveItem] = useState("")

  const styleModalFilter = showFilter
    ? { display: "block" }
    : { display: "none" }

  const styleColumn = showFilter
    ? { flexDirection: "column !important" }
    : { flexDirection: "row !important" }

  const styleChild = showFilter ? { width: "100%" } : {}

  return (
    <Layout hideHeader>
      <div className="explore-container">
        <Header isLogin breakcrumb="Explore >" />
        <div id="content-wrap-main">
          {showIntro && (
            <div className="explore-menu">
              <div className="explore-menu-container">
                <div className="explore-menu-title">
                  Choose a lifestyle, not a floor plan.
                </div>
                <div
                  className="explore-menu-icon cursor-pointer"
                  onClick={() => setShowIntro(false)}
                >
                  <img src="/img/explore/times-circle-regular.svg" />
                </div>
              </div>
              <div className="explore-menu-body">
                Browse all our options to find the perfect layout, facade and
                flow to match for your lifestyle. Favourite items by adding them
                to your board and work to customise your perfect home.
              </div>
            </div>
          )}

          <div className="explore-functional">
            <div
              className="menu-margin explore-functional-button filter-button d-sm-none"
              onClick={() => setShowFilter(!showFilter)}
            >
              <div className="filter-text">FILTER</div>
              <div className="filter-icon">
                <img src="/img/explore/filter.svg" />
              </div>
            </div>
            <Dropdown title="SORT" />
            <CategoryDropdown title="CATEGORY" />

            <div className="menu-margin search-box">
              <input className="filter-text" placeholder="SEARCH" />
              <div className="filter-icon">
                <img src="/img/explore/search.svg" />
              </div>
            </div>
          </div>

          <div className="explore-function-content">
            <FilterResult
              selections={selections}
              setRemoveItem={setRemoveItem}
            />
          </div>

          <div className="explore-content d-flex">
            <div className="right-filter d-none d-sm-block">
              <FilterModal
                setSelections={setSelections}
                removeItem={removeItem}
              />
            </div>

            <div
              className="right-filter"
              style={styleModalFilter}
              removeItem={removeItem}
            >
              <FilterModal setSelections={setSelections} />
            </div>

            <div className="d-flex flex-column left-card">
              <div className="d-flex flex-row flex-1 flex-wrap">
                <div className="child-explore" style={styleChild}>
                  2
                </div>
                <div className="child-explore" style={styleChild}>
                  3
                </div>
                <div className="child-explore" style={styleChild}>
                  4
                </div>
                <div className="child-explore" style={styleChild}>
                  5
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap flex-2">
                <div className="d-flex flex-row flex-sm-column flex-1 w-100 flex-wrap">
                  <div className="child-explore" style={styleChild}>
                    3
                  </div>
                  <div className="child-explore" style={styleChild}>
                    4
                  </div>
                </div>
                <div className="d-flex flex-row flex-sm-column flex-1 w-100 flex-wrap">
                  <div className="child-explore" style={styleChild}>
                    5
                  </div>
                  <div className="child-explore" style={styleChild}>
                    6
                  </div>
                </div>
                <div className="d-flex flex-row flex-sm-column flex-2 w-100">
                  <div className="child-explore" style={styleChild}>
                    7
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row flex-1 flex-1 flex-wrap">
                <div className="child-explore" style={styleChild}>
                  2
                </div>
                <div className="child-explore" style={styleChild}>
                  3
                </div>
                <div className="child-explore" style={styleChild}>
                  4
                </div>
                <div className="child-explore" style={styleChild}>
                  5
                </div>
              </div>
            </div>

            {showLetStart && (
              <div className="register-form-wrap">
                <div className="register-form">
                  <div className="register-header">
                    <div className="title-text">HOW IT WORKS</div>
                    <div
                      className="header-icon"
                      onClick={() => setShowLetStart(false)}
                    >
                      <img src="/img/times-circle.svg" />
                    </div>
                  </div>
                  <div className="explore-instruction">
                    <div className="explore-step explore-step-1">
                      <div className="explore-number-step">1</div>
                      <div className="explore-number-content">
                        <div className="explore-number-text">
                          Create account & simply
                        </div>
                        <div className="explore-number-text">
                          +Add items you love
                        </div>
                      </div>
                    </div>
                    <div className="explore-step explore-step-2">
                      <div className="explore-number-step">2</div>
                      <div className="explore-number-content">
                        <div className="explore-number-text">
                          Pick a style you love or
                        </div>
                        <div className="explore-number-text">
                          customise every detail.
                        </div>
                      </div>
                    </div>
                    <div className="explore-step explore-step-3">
                      <div className="explore-number-step">3</div>
                      <div className="explore-number-content">
                        <div className="explore-number-text">
                          Request a quote and make your
                        </div>
                        <div className="explore-number-text">
                          dream home reality!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center button-wrap">
                    <button
                      className="explore-btn"
                      onClick={() => setShowLetStart(false)}
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <QuickViewModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </Layout>
  )
}

export default ExplorePage
